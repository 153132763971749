import {
  APIError,
  AuthError,
  ConflictError,
  EntityError,
  NotFoundError
} from "@/services/error_service";
import {
  PartModel,
  PartModelRequest,
  PartRequestOptions,
} from "@/models/part";
import axios, { AxiosResponse } from "axios";

import { APIResponse } from "@/models/api_res";
import { Delta } from "@/models/delta";
import Vue from "vue";

/**
 * Creates a new Part Service

 * @example
 * const partService = new PartService();
 * partService.getpart();
 */

export default class PartService {
  /**
   * Get paginated list of part
   * Filtered by parameters
   * @param {object} optionsObject optional:
   *  {
   *    @param {number} page page of results to return, optional
   *    @param {number} offset number of results to return, optional (default = 10 in the api, setting to 50 here)
   *    @param {string} q Query term for keyword search
   *    @param {boolean} empty Filters the resulting categories to only include empty categories.
   *    @param {boolean} hidden Filters the resulting categories to only include empty categories.
   *
   *
   *
   * @returns {APIResponse} - response object
   */
  public async getParts(
    optionsObject?: PartRequestOptions
  ): Promise<APIResponse> {
    let parts;
    let url = process.env.VUE_APP_API_URL_NODE + "/products";
    if (optionsObject) {
      let counter = 0;
      let char = "?";
      if (optionsObject.page) {
        counter++;
        if (counter > 1) {
          char = "&";
        }
        url += char + "page=" + optionsObject.page;
      }
      if (optionsObject.offset) {
        counter++;
        if (counter > 1) {
          char = "&";
        }
        url += char + "per_page=" + optionsObject.offset;
      }
      if (optionsObject.q) {
        counter++;
        if (counter > 1) {
          char = "&";
        }
        url += char + "q=" + optionsObject.q;
      }
      if (optionsObject.filter) {
        counter++;
        if (counter > 1) {
          char = "&";
        }
        url += char + optionsObject.filter;
      }
      if (optionsObject.empty) {
        counter++;
        if (counter > 1) {
          char = "&";
        }
        url += char + "empty";
      }
      if (optionsObject.hidden) {
        counter++;
        if (counter > 1) {
          char = "&";
        }
        url += char + "hidden";
      }

      if (optionsObject.bu) {
        counter++;
        if (counter > 1) {
          char = "&";
        }
        url += char + "bu=" + optionsObject.bu;
      }

      url += "&part=true";
    }
    try {
      const options = {
        withCredentials: true
      };
      const res = await axios.get(url);
      parts = res.data.data;
    } catch (err) {
      if (err.response && err.response.status === 401) {
        throw new AuthError(err.response);
      } else {
        throw new APIError("Something went wrong. Please try again.");
      }
    }
    return parts;
  }

  /**
   * POST new part to server
   * @param {Part} request - new part data object
   * @returns {Part} - newly created part object
   */
  public async createNewPart(
    request: PartModelRequest
  ): Promise<PartModel> {
    let part;

    const options = {
      headers: {
        "Content-Type": "application/json"
      },
      withCredentials: true
    };
    const body = JSON.stringify(request);
    try {
      const res = await axios.post(
        process.env.VUE_APP_API_URL_NODE + "/products",
        body,
        options
      );
      part = res.data.data;
    } catch (err) {
      if (err.response && err.response.status === 401) {
        throw new AuthError(err.response);
      } else if (err.response && err.response.status === 409) {
        throw new ConflictError("Error: Part already exists.");
      } else {
        throw new APIError("Something went wrong. Please try again.");
      }
    }
    return part;
  }

  /**
   * Get selected single part
   * @param {number} id - part id
   * @returns {Part} - selected part's data object
   */
  public async getSinglePart(id: number): Promise<PartModel> {
    let part: PartModel;

    const options = {
      withCredentials: true
    };
    try {
      const res = await axios.get(
        process.env.VUE_APP_API_URL + "/products/" + id
      );
      part = res.data;
    } catch (err) {
      if (err.response && err.response.status === 401) {
        throw new AuthError(err.response);
      }
      if (err.response && err.response.status === 404) {
        throw new NotFoundError("Error: Requested part not found.");
      } else {
        throw new APIError(
          "Something went wrong. Please try again.",
          err.status
        );
      }
    }
    return part;
  }

  /**
   * Save current part
   * @param {number} id - part id
   * @param {Part} request - part request data
   * @returns {Promise<void>}
   */
  public async savePart(
    request: PartModelRequest,
    id: number
  ): Promise<void> {
    const options = {
      headers: {
        "Content-Type": "application/json"
      },
      withCredentials: true
    };
    const body = JSON.stringify(request);
    try {
      await axios.patch(
        process.env.VUE_APP_API_URL_NODE + "/products/" + id,
        body,
        options
      );
    } catch (err) {
      if (err.response && err.response.status === 401) {
        throw new AuthError(err.response);
      }
      if (err.response && err.response.status === 404) {
        throw new NotFoundError("Error: Requested part not found.");
      } else if (err.response && err.response.status === 409) {
        throw new ConflictError("Error: Part already exists.");
      } else {
        throw new APIError("Something went wrong. Please try again.");
      }
    }
  }

  /**
   * Delete part
   * @param {number} id - part id
   * @returns {Promise<void>}
   */
  public async deletePart(id: number): Promise<void> {
    try {
      const options = {
        withCredentials: true
      };
      return await axios.delete(
        process.env.VUE_APP_API_URL + "/parts/" + id,
        options
      );
    } catch (err) {
      if (err.response && err.response.status === 401) {
        throw new AuthError(err.response);
      }
      if (err.response && err.response.status === 404) {
        throw new NotFoundError("Error: Requested part not found.");
      } else {
        throw new APIError("Something went wrong. Please try again.");
      }
    }
  }

  
}
