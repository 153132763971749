
import { Component, Watch } from "vue-property-decorator";
import draggable from "vuedraggable";
import PartMenu from "@/components/part/PartMenu.vue";
import Icon from "@/components/reusable/Icon.vue";
import Table from "@/components/reusable/table/Table.vue";
import PartService from "@/services/part_service";
import { getImage } from "@/utility/helpers";
@Component({
  components: {
    draggable,
    PartMenu,
    Icon,
  },
})
export default class PartTable extends Table {
  protected partService = new PartService();
  protected getImage = getImage;
  @Watch("tableData", { deep: true })
  onDataChange(): void {
    this.resetAllCheckboxRowData();
  }

  public async visibilityRequest(id: number, hide: boolean): Promise<void> {
    this.$emit("editVisibility", [id], hide);
  }

  public visibilityRequestMultiple(hide: boolean): void {
    this.$emit("editVisibility", this.selected, hide);
    this.resetAllCheckboxRowData();
  }
}
